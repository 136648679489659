import { CommonResponse, CommonSearchParams, OffsetPagination, OffsetPaginationQuery } from '@api/common/types';
import { DeliveryMethod } from '@api/logistic';

import { PRICE_UNITS, TYPES_ENUM } from '@scripts/enums';

import { Banner } from './banners';
import { Brand } from './brands';
import { Category } from './categories';
import { ProductImage } from './product-image';

export interface ProductFilter {
    id: number;
    seller_id: number;
    name: string;
    query: string;
    code: string;
    category_id: number | number[];
    barcode: string;
    vendor_code: string;
    is_profitable: boolean;
    is_hit: boolean;
    price_gte?: number | undefined;
    price_lte?: number | undefined;
    name_like: string;
    reference_card_id: number;
}

export interface ProductCreateParams {
    external_id: string | null;
    category_id?: number;
    brand_id?: number;
    name: string;
    code?: string;
    description?: string;
    type: number;
    allow_publish?: boolean;
    vendor_code: string;
    barcode?: string;
    weight?: number;
    weight_gross?: number;
    length?: number;
    width?: number;
    height?: number;
    is_adult?: boolean;
    base_price?: number;
    images?: {
        id?: number;
        name?: string;
        sort: number;
        preload_file_id: number;
        url?: string;
    }[];
}

export interface Product {
    id: number;
    allow_publish: boolean;
    barcode: null;
    base_price: null;
    brand_id: null;
    category_id: number;
    code: string;
    created_at: string;
    description: string;
    external_id: null;
    height: number;
    is_adult: false;
    length: number;
    name: string;
    type: number | string;
    updated_at: string;
    vendor_code: string;
    weight: number;
    weight_gross: number;
    width: number;
    main_image: string;
    images?: {
        id: number;
        is_external: boolean;
        name: string;
        sort: number;
        url: string;
    }[];
    vat: number;
    qty: number;
    qty_available: number | null;
    price: number;
}

export interface ProductProp {
    data: Product[];
    meta: {
        pagination: OffsetPagination;
    };
}


export interface ProductResponse {
    data: Product;
    meta: {
        pagination: OffsetPagination;
    };
}

export interface ProductAttributeValue {
    type: string;
    name: string;
    code: string;
    values: { name: string; value: string | boolean }[];
    value?: string;
    property_id?: number;
}

export interface ProductDetailBase {
    id: number;
    product_id: number;
    seller_id: number;
    main_image: string;
    category_id?: number;
    category_chain: { id: number; name: string; code: string }[];
    brand_id?: number;
    code: string;
    name: string;
    description?: string;
    type: TYPES_ENUM;
    vendor_code: string;
    barcode?: string;
    weight: number;
    weight_gross: number;
    length: number;
    height: number;
    width: number;
    is_adult?: boolean;
    is_fragile: boolean;
    is_flammable: boolean;
    is_dangerous: boolean;
    is_tax_tracked: boolean;
    reference_card_id?: number | null;
    reference_offers_count: number;
    vat: number;
    certificate_file?: string;
    offer_id?: number;
    price_unit: PRICE_UNITS;
    store_id: number;
    quantity: number;
    qty: number;
    cost: number;
    cost_per_one?: number;
    base_price: {
        price: number;
        cost: number;
        discount_percent: number;
    };
    personal_price?: {
        price: number;
        cost: number;
        discount_percent: number;
    };
    price: number;
    qty_available?: number | null;
    discounts?: Discount[];
}

export interface ProductAllRoundView {
    url: string;
    code: string;
    contentType: string;
}

export interface NameplateItem {
    id: number;
    image: string;
    position: number;
    type: number;
    name: string;
}
export interface OrderProductService {
    id: number;
    order_item_id: number;
    service_type: number;
    created_at: string;
    updated_at: string;
    offer_id?: number;
}

export interface Gluing {
    id: number;
    name: string;
    code: string;
    category_chain: {
        id: number;
        name: string;
        code: string;
    }[];
    props: {
        prop_type: string;
        prop_name: string;
        prop_code: string;
        value_value: string;
        value_name: string;
    }[];
}

export type DiscountNameplate = NameplateItem & { discount_id: number; discount_type: number };

export interface Discount {
    id: number;
    nameplate_id: number | null;
    type: number;
    value: number;
    value_type: number;
    variant: number;
    conditions?: { type: number; condition: any }[];
}

export interface ProductDetail extends ProductDetailBase {
    shipment_id?: number;
    brand?: Brand;
    category?: Category;
    seller?: { id: number; name: string };
    images?: ProductImage[];
    files?: ProductImage[];
    videos?: ProductImage[];
    nameplates?: NameplateItem[];
    allRoundView?: ProductAllRoundView[];
    attributes?: ProductAttributeValue[];
    service_types?: ProductService[];
    reference_card_main_image: string;
    reference_min_price?: number;
    gluing: Gluing[];
    discount_nameplates?: DiscountNameplate[];
    discounts?: Discount[];
    is_main?: boolean;
    has_personal_price?: boolean;
    length: number;
    quantity: number;
}

export interface ProductService {
    id: number;
    service_type: number;
    apply: boolean;
}

export interface ProductsImageMutateParams {
    id: number;
    file: FormData;
}

export interface ProductGroupTypeFilter {
    id: number[];
    name: string;
    code: string[];
}

export interface ProductGroupFilterResponse {
    id: number;
    name: string;
    display_name: string;
    code: string;
    is_multiple: true;
    is_filterable: true;
    is_color: true;
    directory: {
        id: number;
        name: string;
        code: string;
    }[];
}

export interface ProductGroupTypeParams {
    sort: string[];
    filter?: ProductGroupTypeFilter;
    include: string[];
    pagination: OffsetPaginationQuery;
}

export interface ProductsTypes {
    id: number;
    name: string;
}

export enum ProductGroupCode {
    promo = 'promo',
    sets = 'sets',
    brands = 'brands',
}

export interface ProductGroupType {
    id: number;
    code: ProductGroupCode;
    name: string;
}

export type ProductGroupTypeResponse = CommonResponse<ProductGroupType[]>;

export interface ProductGroupSearchFilter {
    id?: number;
    name?: string;
    code?: string;
    active?: boolean;
    is_shown?: boolean;
    type_id?: number;
    banner_id?: number;
    category_code?: string;
    'products.product_id'?: number;
}

export interface ProductGroupFilter {
    code: string;
    value: string;
    id: number;
    product_group_id: number;
}

export type ProductGroupFilterParams = CommonSearchParams<
    Partial<{
        is_filterable: boolean;
    }>
> & {
    category: number | null;
};

export interface ProductGroupBase {
    name: string;
    code: string;
    active: boolean;
    is_shown: boolean;
    type_id: number;
    banner_id: number | null;
    category_code: string;
}

export interface ProductGroupProduct {
    sort: number;
    product_id: number;
}

export type ProductGroupCreateParams = ProductGroupBase & {
    products: ProductGroupProduct[];
    filters: {
        code: string;
        value: string;
    }[];
};

export type ProductGroup = ProductGroupBase & {
    id: number;
    preview_photo: string | null;
    filters?: ProductGroupFilter[];
    products?: {
        sort: number;
        product_id: number;
        id: number;
        product_group_id: number;
    }[];
    type?: ProductGroupType;
    banner?: Banner;
};

export interface ProductGroupSearchParams {
    sort: (keyof ProductGroupSearchFilter)[];
    filter?: ProductGroupSearchFilter;
    include: ('filters' | 'products' | 'type' | 'banner' | 'banner.button')[];
    pagination: OffsetPaginationQuery;
}

export type ProductGroupSearchResponse = CommonResponse<ProductGroup[]>;
export type ProductGroupSearchOneResponse = CommonResponse<ProductGroup>;
export type ProductGroupCreateResponse = CommonResponse<ProductGroup[]>;

export interface FacetItem {
    name: string;
    code: string | number;
    values: {
        name: string;
        code: string | number;
        offers_count: number;
    }[];
}

export interface ProductsFilterItem {
    brands: { id: number; code: string; name: string; offers_count: number }[];
    offers_count: number;
    min_price: number;
    max_price: number;
    facet_props: FacetItem[];
}

export interface AnyProductFilterItem {
    name: string;
    dataType: string;
    values: AnyValuesDistinctItem[];
}

export interface AnyValuesSliderItem {
    id: string;
    name: string;
    value: number;
}

export interface AnyValuesDistinctItem {
    id: string;
    name: string;
    value: number;
    open: boolean;
    pictureUrl: string;
    selected: boolean;
    children: AnyValuesDistinctItem[];
    link: string;
}

export interface DeliveryProducts {
    offer_id: number;
    qty: number;
    weight: number;
    width: number;
    height: number;
    length: number;
    store_id: number;
    seller_id: number;
    is_explosive: boolean;
    cost: number;
}

export interface ShipmentProducts {
    shipment_id: string;
    shipment_name: string;
    store_id: number;
    order_deliveries: DeliveryProducts[];
    deliveries: DeliveryMethod[];
    order_items: ProductDetail[];
}

export type FinalProducts = {
    check: boolean;
    price: number;
    qty: number;
    id: number;
    price_per_one: number;
} & ProductDetail;

export interface ProductZeroStockEmailSubscription {
    id?: number;
    verified?: boolean;
}

export interface ProductZeroStockEmailSubscriptionData {
    id: number;
    client_email: string;
    offer_id: number;
    product_id: number;
    seller_id: number;
}

export interface ProductZeroStockEmailSubscriptionVerify {
    subscription_id: number;
    token: string;
}

export interface ProductDetailsResponse {
    data?: ProductDetail
}